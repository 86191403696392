import { unescape } from 'lodash'
import { FieldType } from '@wix/wix-data-schema-types'
import type {
  AdaptedComponent,
  ComponentAdapter,
  ComponentValueConfig,
} from './componentFactory'
import type { WixTextComponent } from './types'
import { baseComponentAdapter } from './baseAdapter'
import { extendComponentAdapter } from '../helpers/extendAdapter'

export const textAdapter: ComponentAdapter<WixTextComponent> = component => {
  const baseApi = baseComponentAdapter(component)

  const extendedApi: Pick<AdaptedComponent, 'setValue' | 'clear'> = {
    setValue(
      value,
      { fieldType, propPath = 'text', binding }: ComponentValueConfig = {},
    ) {
      if (fieldType === FieldType.url) {
        const target = binding?.linkTarget || '_blank'
        component.text = `<a href=${value} target="${target}" style="text-decoration: underline">${value}</a>`
        const { html } = component
        component.text = ''
        component.html = unescape(html)
        return
      }

      baseApi.setValue(value, { fieldType, propPath, binding })
    },

    clear({ fieldType, propPath, binding }: ComponentValueConfig = {}) {
      baseApi.setValue('', {
        fieldType,
        propPath,
        binding,
      })
    },
  }

  return extendComponentAdapter(baseApi, extendedApi)
}
